.issues-donut-graph {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.issues-donut-graph-legend {
  padding-top: 16px;
}

ul.issues-donut-legend-list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: 16px;
}
// li.issues-donut-legend-item {
//   list-style: none;
//   list-style-image: url(../../../../assets/media/netop/legends/donut-legend-item.svg);
//   display: inline-table;
// }
// .relative {
//   position: relative;
// }

.issues-donut-total-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

// .absolute-center {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// .text-center {
//   text-align: center;
// }

p.issues-donut-total {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: #1f78b4;
  font-size: 20px;
  line-height: 24px;
}

.issues-donut-no-actions {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-align: center;
}
