@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";

[class*="sync-properties-list-item"] {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.sync-properties-list-item-content {
  justify-content: space-between;
}
.icon-position {
  position: relative;
  right: 14px;
  bottom: 1px;
}
.icon-position svg {
  height: 20px;
  width: 28px;
}
.icon-and-ellipse {
  position: relative;
  bottom: 9px;
  right: 3px;
}
.single-change-row {
  padding-left: 20px;
}
.properties-list-header-container {
  padding-top: 0.5rem;
  border-top: 1px solid $side-border-color;
}
.properties-list-content-container {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.properties-header-container {
  padding-bottom: 0.5rem;
  width: 100%;
}

.properties-header-container .mat-list-item-content {
  padding-right: 0 !important;
  padding-left: 0.3rem !important;
}
.sync-properties-expans-panel-header {
  margin: 1rem;
  background-color: #eeeeee;
}

[class*="changed-text"] {
  width: 250px;
  @include trim();
}

.changed-text-green {
  color: $possitive-color;
}
.changed-text-red {
  color: $negative-color;
}
.changed-text-blue {
  color: $blue-global;
}

.header-second-part {
  border-left: 1px solid $side-border-color;
  padding-left: 1rem;
}
