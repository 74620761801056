@import "assets/sass/netop/_mixin.scss";

.prop-svg-icon svg {
  left: 15px;
  top: 17px;
  position: absolute;
  width: 27px;
  height: 20px;
}
.prop-svg-root-icon svg {
  top: -1px;
  position: absolute;
  width: 50px;
  height: 50px;
}

/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-element-icon-container.col.col-lg-2 {
  padding-left: 0;
  top: 7px;
  right: 10px;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-first-column-container.col.col-md-4 {
  padding-left: 0;
  padding-right: 0;
  max-width: 27%;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-health-value-container.col {
  padding-left: 19px;
  padding-right: 0;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-undefined-health-value-container.col {
  padding-left: 19px;
  padding-right: 0;
  padding-top: 11px;
}
.prop-health-undefined-error {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #c4c4c4;
}

.prop-first-column-main {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #5c5c5c;
  @include trim;
  width: 100%;
}
.prop-first-column-sub {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5c5c5c;
  position: absolute;
  top: 37px;
  @include trim;
  width: 100%;
}
.prop-key {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5c5c5c;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-key-1.col {
  padding-right: 0px;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-key-2.col {
  padding: 0px;
}
.prop-polygon-1 {
  left: 58px;
  bottom: 0px;
  position: absolute;
}

.prop-polygon-1 svg {
  height: 12px;
  width: 8px;
  &:hover {
    height: 16px;
    width: 12px;
  }
}
.prop-actions-container {
  cursor: pointer;
}
.prop-polygon-2 {
  left: 53px;
  bottom: 0px;
  position: absolute;
}

.prop-polygon-2 svg {
  height: 12px;
  width: 8px;
  &:hover {
    height: 16px;
    width: 12px;
  }
}
.prop-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 27px;
  line-height: 25px;
}

.prop-title-list-item .mat-list-item-content {
  padding: 0 !important;
}
.prop-border-line {
  position: absolute;
  top: 11px;
}
.health-polygon-container {
  width: 88px;
  cursor: pointer;
}

.link-warning-icon-container {
  padding-top: 12px;
}

.health-colomn-container {
  right: 10px;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.actions-colomn-container.col.col-md-3 {
  right: 10px;
  padding-left: 0;
  margin-left: 10px;
}
.prop-error-message {
  justify-content: center;
  display: flex;

  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.prop-no-data-message {
  margin-top: 10px;
  font-size: 14px;
  line-height: 19px;
}
/*
The override is needed because the "dashboard-portal" class (at _dashboard.scss file) changes the bootstrap col classes
*/
.prop-link-first-column-container.col.col-md-4 {
  max-width: 100%;
}
