@import "assets/sass/netop/_mixin.scss";

.dis-devices-header {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.dis-devices-header-container {
  display: flex;
  justify-content: center;
}
.dis-devices-header-container .mat-list-base .mat-list-item .mat-list-item-content {
  align-items: unset;
}
.dis-devices-header-container .mat-list-base {
  padding-top: 0;
}
.dis-devices-header-container .mat-list-item {
  height: 100%;
  padding-bottom: 10px;
}
.dis-ellipse svg {
  height: 45px;
}
.dis-icon {
  position: relative;
  bottom: 33px;
  left: 14px;
}
.dis-www-icon {
  left: -10px;
}
.dis-icon svg {
  height: 16px;
}
.dis-dot {
  bottom: 48px;
  position: relative;
}
.dis-dot svg {
  height: 11px;
}
.dis-device-name {
  position: relative;
  bottom: 20px;
  text-align: center;
  font-size: 11px;
  line-height: 17px;
  color: #5c5c5c;

  width: 77px;
  @include trim(2);
}

.dis-device-container {
  flex-grow: 0.1;
  padding-right: 0;
  padding-top: 5px;
}
.dis-device-row {
  justify-content: center;
  max-height: 160px;
  overflow-y: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.single-device-screen-row {
  max-height: 75px;
}
