export type QueryParamValue = {
  [queryName: string]: string | string[];
}

export type AllActionsQueryParams = ActionsListCommonQueryParamsEnum | ActionsListEntitiesQueryParamsEnum;

export type ActionsListQueryParamValue = {
  [key in AllActionsQueryParams]?: string | string[];
}
export type ActionsListCommonParamsValue = {
  [key in ActionsListCommonQueryParamsEnum]?: string | string[];
}
export type ActionsListEntitiesParamsValue = {
  [key in ActionsListEntitiesQueryParamsEnum]?: string;
}


export enum ActionsListQueryParamsEnum {
  ActionType = 'actionType',
  ActionCategory = 'actionCategory',
  ActionStatus = 'status',
  Search = 'search',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Organization = 'Organization',
  Severities = 'severity',
  Tenant = 'Tenant',
  Venue = 'Venue',
}
export enum ActionsListCommonQueryParamsEnum {
  Severities = 'severity',
  ActionType = 'actionType',
  ActionCategory = 'actionCategory',
  ActionStatus = 'status',
  Search = 'search',
  StartDate = 'startDate',
  EndDate = 'endDate'
}
export enum ActionsListEntitiesQueryParamsEnum {
  Organization = 'Organization',
  Tenant = 'Tenant',
  Venue = 'Venue',
}

export type SingleFilterState = {[filterName: string]: string};
export type MultiFilterState = {[filterName: string]: string[]};
export type ViewFilterStates = {[viewName: string]: {
    singleFilters: SingleFilterState;
    multiFilters: MultiFilterState;
  }};

export type SortCriteria = {
  field: SortByField;
  direction: "Asc" | "Desc";
};

export type ActionRequest = {
  actionTypes?: any;
  category?: any;
  status?: any;
  severity?: any;
  pageSize?: number;
  pageNo?: number;
  sortCriteria?: SortCriteria[];
  startTime?: any;
  endTime?: any;
  search?: any;
  searchBy?: any;
};

export enum SortByField {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  SEVERITY = 'severity',
  NAME = 'name',
  CATEGORY = 'category',
  STATUS = 'status',
  TYPE = 'type'

}
export function mapToSortByField(field: string): SortByField  {
  switch (field) {
    case 'updatedAt':
      return SortByField.UPDATED_AT;
    case 'createdAt':
      return SortByField.CREATED_AT;
    case 'name':
      return SortByField.NAME;
    case 'category':
      return SortByField.CATEGORY;
    case 'status':
      return SortByField.STATUS;
    case 'severity':
      return SortByField.SEVERITY;
    case 'type':
      return SortByField.TYPE;
    default:
      return SortByField.UPDATED_AT;
  }
}
export function formatDirection(direction: "asc" | "desc"): "Asc" | "Desc" {
  return `${direction[0].toUpperCase()}${direction.slice(1)}` as "Asc" | "Desc";
}

