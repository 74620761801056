@import "assets/sass/netop/vars";

.change-log-container {
  height: 77%;
  display: flex;
  align-items: center;
}
[class*="change-text"] {
  &:hover {
    font-weight: 600;
    text-decoration: underline;
  }
}
.change-text-added {
  color: $possitive-color;
}
.change-text-modified {
  color: $medium-color;
}
.change-text-removed {
  color: $negative-color;
}
.entity-icon-container {
  padding-right: 0.3rem;
  position: relative;
  bottom: 0.2975rem;
  text-decoration: underline;
}
.entity-icon {
  height: 20px;
  width: 19px;
}

.entity-icon svg path {
  fill: none;
  stroke-width: 0.6px;
}

.entity-data-container-added:hover .entity-icon svg path {
  stroke-width: 1px;
}
.entity-data-container-modified:hover .entity-icon svg path {
  stroke-width: 1px;
}
.entity-data-container-removed:hover .entity-icon svg path {
  stroke-width: 1px;
}
.entity-data-container-added:hover .change-text-added {
  font-weight: 600;
  text-decoration: underline;
}
.entity-data-container-modified:hover .change-text-modified {
  font-weight: 600;
  text-decoration: underline;
}
.entity-data-container-removed:hover .change-text-removed {
  font-weight: 600;
  text-decoration: underline;
}
.entity-icon-added svg path {
  stroke: $possitive-color;
}
.entity-icon-modified svg path {
  stroke: $medium-color;
}
.entity-icon-removed svg path {
  stroke: $negative-color;
}
