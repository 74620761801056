@import "assets/sass/netop/vars";

.action-conf-dialog-radio-group {
  position: relative;
  top: 3px;
  padding-left: 5px;
}
.action-selection-container {
  height: 185px;
}
.action-conf-dialog-text-default {
  color: $neutral-color;
}
[class*="toggle-btn"] {
  color: $no-color;
  font-size: 14px;
  line-height: 35px;
}
.toggle-btn-high.mat-button-toggle-appearance-standard {
  background-color: $negative-color;
}
.toggle-btn-medium.mat-button-toggle-appearance-standard {
  background-color: $medium-color;
}
.toggle-btn-minor.mat-button-toggle-appearance-standard {
  background-color: $possitive-color;
}
.toggle-btn-group.mat-button-toggle-group {
  height: 36px;
}

.mat-button-toggle-label-content {
  line-height: 36px !important;
}
.toggle-btn-neutral.mat-button-toggle-appearance-standard {
  background-color: $neutral-color;
}
.toggle-btn-yes.mat-button-toggle-appearance-standard {
  background-color: $blue-global;
}
.toggle-btn-no.mat-button-toggle-appearance-standard {
  background-color: $no-color;
  color: $blue-global;
}
.mat-button-toggle-checked {
  color: #000000 !important;
  font-weight: bold;
  font-size: 16px;
}
.action-conf-info-icon {
  height: 15px;
  width: 15px;
}
.action-conf-info-icon svg {
  fill: $neutral-color;
}
.action-conf-info-icon-container {
  position: relative;
  bottom: 14px;
  right: 20px;
}
