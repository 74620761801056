:host {
  z-index: 1;
}

.btn-toolbar {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}
.kt-portlet .kt-portlet__head {
  min-height: 48px;
}
