import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Venue } from '../../models/venues.model';
import { GlobalEntitiesService } from './global-entities.service';
import { IAppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { mergeMap, map, take } from 'rxjs/operators';
import { EntityType } from '../../models/entity-type.enum';
import { EntityAction, ActionCategory, ActionStatus, ActionTypeDto } from "../../models/actions.model";
import moment from 'moment';
import { ActionsTrend, HealthTrend, HealthChangeStatus } from '../../models/health.model';
import { BaseSortFilter } from '../../models/sort-filter/base-sort-filter.model';
import { Page } from '../../models/page.model';
import { BaseEntityActionsService } from './base-entity-actions.service';
import { LegacySeverity } from '../../models/severity.model';
import { StatData } from "../../models/stats.model";
import { TimeUnit } from '../../models/time.model';
import { SortCriteria } from 'src/app/features/anomalies/components/main-actions-dashboard/models/actions-list-query-params.model';


@Injectable({
  providedIn: 'root'
})
export class DashboardService extends GlobalEntitiesService implements BaseEntityActionsService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  fetchOrgVenues(): Observable<Venue[]> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<Venue[]>(`dashboard/${orgId}/venues`))
    )
  }

  fetchGlobalInfo(): Observable<any> {
    return this.http.get('globalInfo/');
  }

  fetchDashboardTenantsState(): Observable<StatData> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<StatData>(`dashboard/${orgId}/tenants/stats`))
    )
  }

  fetchDashboardVenuesState(): Observable<StatData> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<StatData>(`dashboard/${orgId}/venues/stats`))
    )
  }

  fetchDashboardDevicesState(): Observable<StatData> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<StatData>(`dashboard/${orgId}/devices/stats`))
    )
  }

  fetchDashboardTasksState(): Observable<StatData> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<StatData>(`dashboard/${orgId}/tasks/stats`))
    )
  }

  fetchDashboardLatestActivities(): Observable<any> {
    return this.http.get('dashboard/activities/latest');
  }

  /**
   * Get issues of an entity (organization or tenant)
   * @param entityType The enitityType to get issues of (organization/tenant)
   * @param entityId The enitityId to get issues of
   * @param groupBy Group by (severity/category)
   * @param timespan The timespan to go back by in days (current = 7)
   */
  fetchIssues(groupBy?: ('Severity' | 'Category'), timespan: number = 7): Observable<Array<{ category, label, value }>> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<Array<{ category, label, value }>>
        (`dashboard/issues/${entity.type}/${entity.id}?groupBy=${groupBy}&timespan=${timespan}`))
    );
  }

  /**
   * Get health status of current entity
   * @param queryType What to count (either fabrics/venues/devices or tenants)
   * @param timespan The timespan to go back by in days (current = 7)
   */
  fetchHealthStatus(queryType: EntityType): Observable<HealthChangeStatus> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<HealthChangeStatus>(`dashboard/health/status/${entity.type}/${entity.id}?queryType=${queryType}`))
    )
  }

  /**
   * Get health status of current entity
   * @param queryType What to count (either fabrics/venues/devices or tenants)
   * @param time current Time
   */
  fetchHealthStatusByTime(queryType: EntityType, time: string): Observable<HealthChangeStatus> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<HealthChangeStatus>(`dashboard/health/status/${entity.type}/${entity.id}?queryType=${queryType}&time=${time}`))
    )
  }

  /**
   * Get health trend time series of current entity
   * @param queryType What to count (either fabrics/venues/devices or tenants)
   * @param timespan The timespan to go back by in days (current = 7)
   */
  fetchHealthTrend(queryType: EntityType, timespan: number = 7): Observable<HealthTrend> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<any>
        (`dashboard/health/trend/${entity.type}/${entity.id}?queryType=${queryType}&timespan=${timespan}`)),
      map((trend) => {
        // For whatever reason server returns {date,value} instead of {*datetime*,value}
        let total: any = trend.total.map((point) => {
          return {
            datetime: moment(point.datetime || point.date),
            value: point.value
          }
        });
        let unhealthy: any = trend.unhealthy.map((point) => {
          return {
            datetime: moment(point.datetime || point.date),
            value: point.value
          }
        });
        // console.log("total %o", total)
        // console.log("unhealthy %o", unhealthy)
        let healthTrend = new HealthTrend();
        healthTrend.unhealthy = unhealthy
        healthTrend.total = total
        return healthTrend;
      })
    )
  }

  /**
   * Get actions trend of current entity
   * @param timespan The timespan to go back by in days (current = 7)
   */
  fetchActionsTrend(timespan: number = 7): Observable<ActionsTrend> {
    //       'dashboard\\/\\w+\\/\\d+\\/actions': 'getActions',

    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<any>
        (`insights/trend/${entity.type}/${entity.id}?timespan=${timespan}`)),
      map((trend) => {
        let trends: { total: any, closed: any } = { total: [], closed: [] };
        trend.map((point) => {
          trends.total.push({
            datetime: moment(point.datetime || point.time),
            value: point.open
          });
          trends.closed.push({
            datetime: moment(point.datetime || point.time),
            value: point.complete
          });

        })
        return trends;
      })
    )
  }

  /**
   * Fetch the available issues action types (used at least for filter selection on grid)
   */
  fetchActionsTypes(): Observable<Array<ActionTypeDto>> {
    return this.http.get<Array<ActionTypeDto>>(`insights/action-types`)
  }

  /**
   * fetch action count (used in actions donut)
   * @param timespan timespan to got back
   */
  fetchActionsCount(groupBy?: ('Severity' | 'Category'), timespan: number = 7): Observable<Array<{ category, label, value }>> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.http.get<Array<{ category, label, value }>>
        (`insights/count/${entity.type}/${entity.id}?groupBy=${groupBy}&timespan=${timespan}`)),
      map((counts) => {
        counts.map(count => {
          count.category = groupBy == "Severity" ? LegacySeverity.fromString(count.category) : count.category;
          return count;
        })
        return counts;
      })
    );
  }

  /**
   * Fetch actions list for an entity
   * @param entityType the entity type to fetch actions for
   * @param sortAndFilter
   */
  fetchEntityActions(entityType: EntityType, sortAndFilter: BaseSortFilter, entityId?: number): Observable<Page<EntityAction>> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => {
        return this.http.get<Page<EntityAction>>
          (sortAndFilter.appendToURL(`insights/list/${entityType}/${entityId || entity.id}`));
      }),
      map((page: Page<EntityAction>) => {
        page.data.forEach((action) => {
          action.severity = LegacySeverity.fromString(action.severity)
          action.categoryName = ActionCategory.getCategoryName(action.category)
        })
        return page
      })
    )
  }

  fetchAnalysisEntityActions(sortCriteria: SortCriteria[], entityType: EntityType, timeBack: number, timeUnit: TimeUnit, date: Date, sortAndFilter: BaseSortFilter, entityId?: number): Observable<Page<EntityAction>> {
    const payload = sortAndFilter.createPayload({timeBack : timeBack, timeUnit : timeUnit, specificDate : sortCriteria})
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => {
          return this.http.post<Page<EntityAction>>(`insights/anomalies/${entityType}/${entityId}`,payload)
      }),
      map((page: Page<EntityAction>) => {
        page.data.forEach((action) => {
          action.severity = LegacySeverity.fromString(action.severity)
          action.categoryName = ActionCategory.getCategoryName(action.category)
        })
        return page
      })
    )
  }

  fetchGroupedEntityActions(groupBy: EntityType, sortAndFilter: BaseSortFilter): Observable<Page<EntityAction>> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => {
        // The groupBy is needed to distinguish between venues & fabrics requests at tenant level
        return this.http.get<Page<EntityAction>>
          (sortAndFilter.appendToURL(`insights/group/${entity.type}/${entity.id}?groupBy=${groupBy}`))
      }),
      map((page: Page<EntityAction>) => {
        page.data.forEach((action) => {
          action.severity = LegacySeverity.fromString(action.severity)
          action.categoryName = ActionCategory.getCategoryName(action.category)
        })
        return page
      })
    )
  }

  performAction(actionId: number, action: ActionStatus, entityType?: EntityType, entityId?: number): Observable<any> {
    return this.http.put(`insights/${entityType}/${entityId}/change_state/${actionId}/${action}`, {})
  }

}
