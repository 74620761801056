export abstract class BaseSortFilter {
    startRow: number = 0;
    endRow: number = 100;
    pageNo: number;
    pageSize: number;
    sortBy: string;
    sortDir: ("asc" | "desc");
    search: string = undefined;

    constructor() { }
    abstract appendToURL(url: string)
    abstract createPayload(params: any)
}
